<template>
  <!-- ============================ Page Title ================================== -->
  <section id="main-wrapper" style="min-height: 95vh" class="text-justify">
    <div class="container">
      <section class="page-title">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="breadcrumbs-wrap">
                <h1 class="breadcrumb-title">Who are we?</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ============================ Page Title End ================================== -->

      <!-- ========================== Who Are We Section =============================== -->
      <section class="pt-0">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="list-facts-wrap">
                <div class="sec-heading mb-3"></div>
                <div class="list-facts">
                  <!--<div class="list_facts_icons"><i class="ti-desktop"></i></div>-->
                  <div class="list-facts-caption">
                    <h4>
                      The easiest mobile application to find the right tutor for
                      your needs!
                    </h4>
                    <p>
                      971tutors connects you to certified and verified teachers
                      and professors for private tutoring lessons for you and
                      your child.
                    </p>
                    <p>
                      We deliver our lessons online, in private or in group
                      lessons, or offline at the comfort of your own home. We
                      provide online sessions in the UAE and around the Middle
                      East, at reasonable prices.
                    </p>
                    <br />
                    <h4>You can book your private tutor in 3 easy steps:</h4>
                    <p>1-Choose your subject.</p>
                    <p>2-Pick from a list of recommended tutors.</p>
                    <p>3-Book the perfect tutor for you.</p>
                    <br />
                    <p></p>

                    <br />
                    <h4>Why choose 971tutors?</h4>
                    <p>
                      We believe every person should have the chance and the
                      opportunity to shine with the right support.
                    </p>
                    <br />
                    <h4>Who are our tutors and our coaches?</h4>
                    <p>
                      We will offer you classes delivered by a wide range of
                      certified and verified school teachers, or university
                      professors, with nothing less than a good tutoring
                      experience. Our coaches are qualified and certified
                      trainers with years of experience and certifications.
                    </p>
                  </div>
                </div>
              </div>
              <!--<a href="#" class="btn btn-modern">Know More<span><i class="ti-arrow-right"></i></span></a>-->
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="list-facts-wrap-img">
                <img
                  src="/img/971TutorsImages/smallkid.jpeg"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ========================== Who Are We Section End =============================== -->

      <!-- ========================== How Much Have We Helped Section =============================== -->
      <section class="pt-0">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="list-facts-wrap">
                <div class="sec-heading mb-3">
                  <h2>How Much We Have Helped?</h2>
                </div>

                <div class="list-facts">
                  <!--<div class="list_facts_icons"><i class="ti-heart"></i></div>-->
                  <div class="list-facts-caption">
                    <p>
                      With an individual and unique learning experience,
                      you/your child will receive an individualized learning
                      experience you, he or she can’t always get in a classroom
                      setting. Tutors or professors can customize the lessons
                      and activities just for you or your child. One-on-one
                      attention, so tutors get to know you/your child’s
                      individual learning style and can adapt teaching methods
                      accordingly.
                    </p>
                    <br />
                    <p>
                      We were able to prepare our students for tests and exams
                      because the tutors worked with the students on specific
                      problem areas. We aim for drastic progress, which we are
                      sure will be able to reach, so that our students’ grades
                      and understandings of the subjects improve to the best.
                    </p>
                    <br />
                    <p>
                      With 971tutors learning became fun, and it gave students
                      the chance to increase their self-esteem and confidence
                      through tutoring, by providing them with the resources and
                      skills he or she needs to excel in school.
                    </p>
                  </div>
                </div>
              </div>
              <!--<a href="#" class="btn btn-modern">Know More<span><i class="ti-arrow-right"></i></span></a>-->
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="list-facts-wrap-img">
                <img
                  src="/img/971TutorsImages/default.jpeg"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ========================== How Much Have We Helped Section End =============================== -->
    </div>
  </section>
</template>

<script>
export default {
  name: "cmp-about",
  mounted() {
    setTimeout(() => {
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 1000);
  },
};
</script>

<style >
</style>